import styled from 'styled-components'

const Container = styled.div`
  display: flex;
  height: 100vh;
  justify-content: center;
  align-items: center;

  img {
    height: fit-content;
    width: fit-content;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
`

function App() {
  return <Container>
    <img src="img/undefi.png" alt="undefi"></img>
  </Container>
}

export default App